<template>
    <InfoCardSlot
      class="recommended-courses-card"
      title="Iknowa Recommended Courses"
      iconName="services.svg"
      customPath="icons/trade-passport-icons"
      actionLabel="View All Accreditations">
      <template #custom-content>
        <div class="recommended-courses-card__content flex-column h-100 w-100">
          <Typography variant="p">
            No courses available
          </Typography>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
  },

  data() {
    return {
    };
  },
});
</script>
<style lang="scss" scoped>
.recommended-courses-card {
  max-height: 400px !important;
  min-height: 400px !important;
  height: auto;
}
</style>
