const ROUTE_TO_SELECT_PROPERTY_PAGE = 'routeToSelectPropertyPage';
const OPEN_FINANCE_MODAL = 'openFinanceModal';
const OPEN_SOLE_TRADER_EVENT = 'openSoleTraderModal';
const ROUTE_TO_PROFILE_PAGE = 'routeToProfilePage';
const ROUTE_TO_FIND_JOBS_PAGE = 'routeToFindJobsPage';
const ROUTE_TO_PROPERTIES_PAGE = 'routeToPropertyListings';
const ROUTE_TO_PROJECTS_PAGE = 'routeToProjects';
const ROUTE_TO_ADD_NEW_PROPERTY_PAGE = 'routeToAddProperty';
export default {
    ROUTE_TO_SELECT_PROPERTY: ROUTE_TO_SELECT_PROPERTY_PAGE,
    OPEN_FINANCE: OPEN_FINANCE_MODAL,
    OPEN_SOLE_TRADER: OPEN_SOLE_TRADER_EVENT,
    ROUTE_TO_PROFILE: ROUTE_TO_PROFILE_PAGE,
    ROUTE_TO_FIND_JOBS: ROUTE_TO_FIND_JOBS_PAGE,
    ROUTE_TO_PROPERTIES: ROUTE_TO_PROPERTIES_PAGE,
    ROUTE_TO_PROJECTS: ROUTE_TO_PROJECTS_PAGE,
    ROUTE_TO_ADD_NEW_PROPERTY: ROUTE_TO_ADD_NEW_PROPERTY_PAGE
};
