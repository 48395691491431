import moment from 'moment';
export default {
    times(interval = 30) {
        const times = []; // Explicitly annotate times array as string[]
        const startTime = 7 * 60; // Start time in minutes (7:00 AM = 420 minutes)
        const endTime = 20 * 60 + 30; // End time in minutes (9:30 PM = 1290 minutes)
        for (let minutes = startTime; minutes <= endTime; minutes += interval) {
            const hour = Math.floor(minutes / 60);
            const minute = minutes % 60;
            const formattedHour = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            const time = `${formattedHour}:${formattedMinute}`;
            times.push(time);
        }
        return times;
    },
    isDateExpired(expiryDate) {
        const currentDate = moment();
        const expirationDate = moment(expiryDate);
        return currentDate.isAfter(expirationDate);
    },
};
