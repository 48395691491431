<template>
    <section class="passport-preview-card card-wrapper flex-column jc-sb">
      <div class="passport-preview-card__main-container flex-column jc-sb">
         <div class="flex-row ai-c jc-sb">
          <Icon iconName="iknowa-logo-v4.svg" iconHeight="24px" iconWidth="106px"/>
          <StatusState
            status="TRADE PASSPORT PREVIEW"
            bgColor="transparent"
            boxShadow="unset"
            fontColor="#7F82A2"
            border="1px solid #7F82A2"/>
        </div>
        <div class="passport-preview-card__wrapper-with-border-bottom flex-column gap-half">
          <Typography variant="h4">
              {{ getFullName }}
          </Typography>
          <span class="flex-row ai-c js-sb">
            <Typography variant="p" textColor="rgba(12, 15, 74, 0.5)" textWeight="400">
              {{ getAddress }}
            </Typography>
            <Typography variant="p" textColor="rgba(12, 15, 74, 0.5)" textWeight="400" v-if="user.postcode">
            <el-icon :size="12" color="#0C0F4A"><LocationFilled /></el-icon>
              {{ getPostcode }}
            </Typography>
          </span>
        </div>
        <div class="passport-preview-card__wrapper-with-border-bottom flex-column gap-half">
          <Typography variant="p" textWeight="700">
            Iknowa Ltd
          </Typography>
          <div class="flex-row ai-c gap-half">
            <span class="passport-preview-card__check-icon flex-row ai-c jc-c">
              <el-icon color="#FFF" :size="10"><Select/></el-icon>
            </span>
            <Typography variant="p" textColor="#40AE42" textWeight="400">
              Company Verified On Iknowa
            </Typography>
          </div>
        </div>
        <div class="passport-preview-card__wrapper-with-border-bottom grid-fill-70 gap-half">
          <div class="flex-column ai-c jc-c gap-half" v-for="(item, index) in 3" :key="index">
            <span class="passport-preview-card__category-icon flex-row ai-c jc-c">
              <inline-svg
              class="passport-preview-card__img"
              width="20px" height="20px"
              :src="require(`@/assets/category-icons/carpentry-joinery.svg`)"></inline-svg>
          </span>
            <Typography
              variant="p"
              textColor="#264FD5"
              lineHeight="18px"
              textWeight="400"
              textAlign="center">
              Handyman Services
            </Typography>
          </div>
        </div>
        <div class="passport-preview-card__wrapper-with-border-bottom grid-fill-100 gap-half">
          <div class="flex-column ai-fs jc-c gap-0" v-for="(item, index) in 3" :key="index">
            <Typography
              variant="p"
              textWeight="700">
              Plumbing & Heating
            </Typography>
            <Typography
              variant="p"
              textWeight="400">
              Level 3 (College)
            </Typography>
          </div>
        </div>
      </div>
      <slot name="custom-footer"></slot>
   </section>
</template>
<script>
import { LocationFilled, Select } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import Icon from '@/core/components/ui/Icon.vue';
import StatusState from '@/core/components/ui/StatusState.vue';
import Typography from '@/core/components/ui/Typography.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    Icon,
    Typography,
    StatusState,
    LocationFilled,
    Select
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),

    getFullName() {
      const { user } = this;

      return `${user?.firstName} ${user?.lastName}` || '';
    },

    getAddress() {
      return this.user?.address || '';
    },

    getPostcode() {
      return this.user?.postcode || '';
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/variables/layout" as *;

.passport-preview-card {
  height: 100%;
  max-height: 590px;
  // min-height: 540px;
  position: relative;
  background: #FFF;
  // padding: 2rem 1.5rem;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  padding: 1rem;
  padding-bottom: 0;

  &__main-container {
    overflow-y: auto;
  }

  &__content {
    min-width: 814px;
    min-height: 470px;
    max-height: 470px;
    height: 100%;
    background: transparent;
    grid-template-columns: 1fr 1fr;
  }

  &__wrapper {
    height: 100%;
    position: relative;
  }

  &__wrapper-with-border-bottom {
    border-bottom: 1px solid #C5D2F2;
    padding-bottom: 1rem;
  }

  &__wrapper-with-border-bottom.grid-fill-70 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));;
  }

  &__wrapper-with-border-bottom.grid-fill-100 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));;
  }

  &__wrapper-with-border-bottom:last-child {
    border-bottom: none;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 20px;
  }

  &__check-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    background: #40AE42;
    border-radius: 100%;
    box-sizing: border-box;
  }

  &__category-icon {
    width: 40px;
    height: 40px;
    min-width: 16px;
    background: #C5D2F2;
    border-radius: 100%;
    box-sizing: border-box;
  }

  &__img {
    :deep(path) {
      fill: #264FD5;
      fill-opacity: 1;
    }
  }
}

.passport-preview-card__wrapper-with-border-bottom:last-of-type {
  border-bottom: none;
}

@media screen and (max-width: 1080px) {
  .passport-preview {
    &__content {
      min-width: 700px;
    }
  }
}

@include media-sm-max-width() {
  .passport-preview {
    width: 100%;
    padding: 1.5rem 1.5rem 0 1.5rem;

    &__content {
      min-width: unset;
      max-height: unset;
      width: 100%;
      grid-template-columns: 1fr;
    }

    &__wrapper.card-wrapper {
      border-radius: 0;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }

    &__arrow {
      top: 70%;
      right: 20%;
    }
  }
}

@include media-xs-max-width() {
  .passport-preview {
    &__content {
      min-width: 300px;
    }
  }
}

@media screen and (max-width: 390px) {
  .passport-preview {
    padding: 1.5rem 2rem 0 1rem;
  }
}
</style>
