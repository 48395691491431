<template>
    <div class="expandable-item" :class="{'with-border': hasBorder}">
       <slot></slot>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    height: {
      type: String,
      default: 'auto',
    },
    width: {
      type: String,
      default: 'auto',
    },
    bgColor: {
      type: String,
      default: 'rgb(3, 155, 229)'
    },
    padding: {
      type: String,
      default: '5px 8px',
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  }
});
</script>
<style lang="scss" scoped>
  .expandable-item {
    height: v-bind('$props.height');
    width: v-bind('$props.width');
    background: v-bind('$props.bgColor');
    padding: v-bind('$props.padding');
    border: none;
    border-radius: 5px;
  }
  .expandable-item.with-border {
    border: 1px solid #FFF;
  }
</style>
