<template>
    <div class="event-item flex-row ai-c" :class="{'no-shadows': noShadows}">
      <LabeledThumbnail
        style="width: 100%;"
        :borderRadius="borderRadius"
        :iconSize="iconSize"
        :hasCustomLabel='true'
        :img="schedule?.user?.userPublicProfile?.profileImage"
        :label="schedule?.userWorkstation?.name">
        <template #custom-label>
          <div class="event-item__wrapper flex-column ai-fs js-fs" style="gap: .3rem;">
            <p class="event-item__label bold default-text">{{ title }}</p>
            <div class="event-item__wrapper flex-row ai-c jc-sb">
              <span class="flex-row ai-c" style="gap: .3rem">
                <inline-svg :src="require(`@/assets/icons/` + iconPreferenceType)"></inline-svg>
                <p class="event-item__label default-text">{{ labelPreferenceType }}</p>
              </span>
              <!-- NOTE: Replace time with the selected time slot -->
              <span class="flex-row ai-c" style="gap: .3rem">
                <inline-svg :src="require(`@/assets/icons/clock-icon.svg`)"></inline-svg>
                <p class="event-item__label default-text">
                  {{ time }}
                </p>
              </span>
            </div>
          </div>
        </template>
      </LabeledThumbnail>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import LabeledThumbnail from '@/core/components/ui/LabeledThumbnail.vue';
import scheduleConstants from '@/core/constants/calendar';

export default defineComponent({
  props: ['noShadows', 'schedule', 'borderRadius', 'iconSize', 'time'],
  components: {
    LabeledThumbnail
  },
  computed: {
    iconPreferenceType() {
      let icon = 'home-icon.svg';
      if (this.schedule && this.schedule?.preference === scheduleConstants.VIDEO_CALL) {
        icon = 'video-icon.svg';
        return icon;
      }
      return icon;
    },
    labelPreferenceType() {
      let label = 'Google meet';
      if (this.schedule && this.schedule?.preference === scheduleConstants.IN_PERSON) {
        label = `${this.schedule?.user?.address} ${this.schedule?.user?.postcode}`;
        return label;
      }
      return label;
    },
    title() {
      let title = 'Site meeting (In-person)';
      if (this.schedule && this.schedule?.preference === scheduleConstants.VIDEO_CALL) {
        title = 'Video meeting (Video-call)';
        return title;
      }
      return title;
    },
  },
  methods: {
  }
});
</script>
<style lang="scss" scoped>
  .event-item {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 0px #0C0F4A33;
    border: none;
    border-radius: 20px;
    background: #FFF;

    &__label {
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0.15000000596046448px;
        color: rgba(12, 15, 74, 0.5);
    }
    &__label.bold {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: #0C0F4A;
    }
    &__wrapper {
      width: 100%;
    }
  }
  .event-item.no-shadows {
    padding: 1rem 0;
    box-shadow: none;
    border-radius: 0;

    &:not(:last-child) {
        border-bottom: 1px solid rgba(12, 15, 74, 0.2);
    }
  }
</style>
