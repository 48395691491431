<template>
  <div class="dashboard default-layout-desktop-padding" v-if="hasMainLayoutInitialized">
    <div class="dashboard__content flex-column h-100">
      <WelcomeBanner
        header="Welcome to your iknowa HQ, Allar"
        :buttonLabel="getPrimaryButtonLabel"
        @handle-event="onHandlePrimaryButtonClick">
          <template #secondary-action>
            <Button
              @handle-click="onHandleSecondaryButtonClick"
              :buttonText="getSecondaryButtonLabel"
              :isActive="true"
              activeColor="#FFF"
              activeFontColor="#4F55F0"
              borderColor="#4F55F0"/>
          </template>
      </WelcomeBanner>
      <DashboardSoleTraderVerified v-if="isVerified && !isLimitedCompany"/>
      <DashboardSoleTraderUnverified v-if="!isVerified && !isLimitedCompany"/>
      <DashboardLimitedCompanyVerified v-if="isLimitedCompany"/>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import WelcomeBanner from '@/core/components/layouts/WelcomeBanner.vue';
import Button from '@/core/components/ui/Button.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import DashboardLimitedCompanyVerified from '@/modules/dashboard/components/dashboard-layouts/DashboardLimitedCompanyVerified.vue';
import DashboardSoleTraderUnverified from '@/modules/dashboard/components/dashboard-layouts/DashboardSoleTraderUnverified.vue';
import DashboardSoleTraderVerified from '@/modules/dashboard/components/dashboard-layouts/DashboardSoleTraderVerified.vue';
import { OPPORTUNITY_ROUTE } from '@/modules/opportunity/routes';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';

const SOLE_TRADER = 'sole-trader';
const LIMITED_COMPANY = 'limited-company';

export default defineComponent({
  components: {
    WelcomeBanner,
    Button,
    DashboardSoleTraderUnverified,
    DashboardSoleTraderVerified,
    DashboardLimitedCompanyVerified
  },

  data() {
    return {
      UserVerificationStatusEnum,
      LIMITED_COMPANY,
      SOLE_TRADER,

      currentDashBoardState: SOLE_TRADER
    };
  },

  computed: {
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation', 'activeUserWorkstation']),
    ...mapGetters(USERS_STORE, ['user', 'isCohort', 'createUserWorkstation', 'isProfileVerifiable', 'hasMainLayoutInitialized', 'wsRole', 'isHosted', 'isWsAdmin']),

    userProfileState() {
      return this.activeUserWorkstation?.verificationStatus;
    },

    isVerified() {
      return this.userProfileState === UserVerificationStatusEnum.VERIFIED;
    },

    getPrimaryButtonLabel() {
      if (this.isVerified) {
        return 'CREATE YOUR TRADES WORKSTATION';
      }
      return 'Verify Your Profile';
    },

    getSecondaryButtonLabel() {
      if (this.isVerified) {
        return 'EXPLORE UP-SKILLING OPPORTUNITIES';
      }
      return 'CREATE YOUR TRADES WORKSTATION';
    },

    isLimitedCompany() {
      return this.currentDashBoardState === LIMITED_COMPANY;
    }
  },

  methods: {
    ...mapActions(['setWorkstationSetupModal']),

    onHandlePrimaryButtonClick() {
      if (this.isVerified) {
        this.setWorkstationSetupModal(true);
      }
    },

    onHandleSecondaryButtonClick() {
      if (!this.isVerified) {
        this.setWorkstationSetupModal(true);
      } else {
        this.$router.push({ name: OPPORTUNITY_ROUTE });
      }
    }
  },
});
</script>
<style lang="scss" scoped>
.dashboard {
  overflow-y: scroll;
}
</style>
