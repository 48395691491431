<template>
    <InfoCardSlot
      class="team-management-card"
      title="Manage Team"
      iconName="workspace-logo-two.svg"
      actionLabel="See All Activity">
      <template #custom-content>
        <div class="team-management-card__content flex-column h-100 w-100">
          <Typography variant="p">
            No team at the moment
          </Typography>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
  },

  data() {
    return {
    };
  },
});
</script>
<style lang="scss" scoped>
.team-management-card {
  max-height: 200px !important;
  min-height: 200px !important;
  height: auto;
}
</style>
