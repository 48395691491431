import { defineComponent } from 'vue';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import CalendarEventBoxComponent from '@/modules/dashboard/components/new-components/CalendarEventBoxComponent.vue';
export default defineComponent({
    components: {
        CalendarEventBoxComponent,
        DefaultCardSlot
    },
    props: {
        title: {
            type: String
        },
        hasShadow: {
            type: Boolean,
            default: true,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
        cardHeight: {
            type: String,
            default: '397px'
        },
        borderRadius: {
            type: String,
            default: '50px'
        },
        iconSize: {
            type: String,
            default: '30px'
        }
    }
});
