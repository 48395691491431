<template>
    <div class="dashboard-sole-trader-verified w-100 h-100 grid">
      <section class="dashboard-sole-trader-verified__container h-100 w-100 flex-column">
        <PassportPreviewCard>
          <template #custom-footer>
            <section class="dashboard-sole-trader-verified__footer w-100 h-auto flex-row ai-c">
                <div class="w-100 flex-row ai-c jc-fe">
                <ActionItemComponent
                  @call-to-action="routeToTradesPassportPage"
                  label="Explore Your Trade Passport"
                  icon="arrow-right.svg"
                  iconSize="20"
                  fontColor="#4B4BFF"
                  fontSize="14px"
                  :isReverse="true"
                  iconColor="#4B4BFF"/>
                </div>
            </section>
          </template>
        </PassportPreviewCard>
        <JobsWidget/>
      </section>
      <section class="dashboard-sole-trader-verified__container h-100 w-100 flex-column">
        <DashboardInsightsCard/>
        <DashboardCompanyWorkstationCard/>
      </section>
      <section class="dashboard-sole-trader-verified__container h-100 w-100 flex-column">
        <EventWidget cardHeight="100%"/>
        <RecommendedCoursesCard/>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import PassportPreviewCard from '@/core/components/common/cards/PassportPreviewCard.vue';
import EventWidget from '@/core/components/common/widgets/EventWidget.vue';
import JobsWidget from '@/core/components/common/widgets/JobsWidget.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import DashboardCompanyWorkstationCard from '@/modules/dashboard/components/cards/DashboardCompanyWorkstationCard.vue';
import DashboardInsightsCard from '@/modules/dashboard/components/cards/DashboardInsightsCard.vue';
import RecommendedCoursesCard from '@/modules/dashboard/components/cards/RecommendedCoursesCard.vue';
import { TRADE_PASSPORT_ROUTE } from '@/modules/trade-passport/routes';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';

export default defineComponent({
  components: {
    DashboardInsightsCard,
    PassportPreviewCard,
    ActionItemComponent,
    EventWidget,
    DashboardCompanyWorkstationCard,
    JobsWidget,
    RecommendedCoursesCard
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation']),
  },

  methods: {
    routeToTradesPassportPage() {
      this.$router.push({ name: TRADE_PASSPORT_ROUTE });
    }
  },
});
</script>
<style lang="scss" scoped>
.dashboard-sole-trader-verified {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

  &__card {
    max-height: unset;
  }

  &__footer {
    min-height: 60px;
    border-top: 1px solid #C5D2F2;
  }
}
</style>
