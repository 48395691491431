<template>
    <ExpandableItem :height="height" :width="width" :hasBorder="hasBorder">
        <template #>
           <div class="event-time flex-column ai-fs" style="gap: .3rem">
               <p class="event-time__text default-text">{{ title === null ? '(No title)' : title }}</p>
               <p class="event-time__text default-text">{{ time }}</p>
           </div>
        </template>
    </ExpandableItem>
</template>
<script>
import { defineComponent } from 'vue';

import ExpandableItem from '@/core/components/ui/ExpandableItem.vue';

export default defineComponent({
  components: {
    ExpandableItem
  },
  props: {
    height: {
      type: String,
      default: 'auto',
    },
    width: {
      type: String,
      default: 'auto',
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
    },
    time: {
      type: String,
      default: '-',
      required: true
    }
  }
});
</script>
<style lang="scss" scoped>
.event-time {
    &__text {
      color: #FFF;
    }
}
</style>
