<template>
  <GlobalDynamicHeader :hasEndCustomComponent="true" :hasStartCustomComponent="true">
    <template #start-custom-component>
      <p class="header-text default-text">{{ currentMonth }} <span>{{ currentYear }}</span></p>
    </template>
    <template #end-custom-component>
      <div class="date-menu flex-row ai-c jc-c">
        <el-icon @click="previousMonth" :size="17" class="cursor-pointer" v-if="!isDisplayOnly"><ArrowLeftBold/></el-icon>
        <p class="date-text default-text cursor-pointer" @click="goToToday">Today</p>
        <el-icon @click="nextMonth" :size="17" class="cursor-pointer" v-if="!isDisplayOnly"><ArrowRightBold/></el-icon>
      </div>
    </template>
  </GlobalDynamicHeader>
</template>
<script>
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';

import GlobalDynamicHeader from '@/core/components/common/header/GlobalDynamicHeader.vue';

export default defineComponent({
  components: {
    GlobalDynamicHeader,
    ArrowLeftBold,
    ArrowRightBold
  },
  emits: ['on-set-previous-month', 'on-set-next-month', 'on-set-today'],
  props: ['currentMonth', 'currentYear', 'isDisplayOnly'],
  methods: {
    previousMonth() {
      this.$emit('on-set-previous-month');
    },
    nextMonth() {
      this.$emit('on-set-next-month');
    },
    goToToday() {
      this.$emit('on-set-today');
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.header-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.15000000596046448px;

  span {
    font-weight: 400;
  }
}
.date-menu {
    width: auto;
    height: 36px;
    min-width: 110px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.1);
    border-radius: 8px;
    padding: 0 .3rem;
    box-sizing: border-box;
}
.date-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
}

@include media-xs-max-width() {
  .date-menu {
    height: 32px;
    min-width: 100px;
  }
}
</style>
