<template>
    <div class="details-box-item flex-column jc-sb" :class="{'has-footer': ctaText || footerLabel}">
        <div class="details-box-item__row flex-column ai-c jc-c">
            <inline-svg :src="icon"></inline-svg>
            <p class="details-box-item__data-text default-text" :class="{'light-text': isDarkBg}">{{data}}</p>
            <p class="details-box-item__label-text default-text" :class="{'light-text': isDarkBg}">{{label}}</p>
        </div>

        <!-- FOOTER EITHER CALL TO ACTION OR FOOTER LABEL -->
        <div class="details-box-item__row flex-row ai-c jc-fe" v-if="ctaText || footerLabel">
            <p class="details-box-item__cta-text default-content" v-if="ctaText">
                <a href="#" class="link" v-if="hasLinkTo" @click="onNavigateLink">
                    {{ ctaText }}
                    <el-icon :size="12" color="#FFA500"><ArrowRightBold/></el-icon>
                </a>
                <span v-else>{{ctaText}}
                    <el-icon :size="12" color="#FFA500" v-if="ctaText"><ArrowRightBold/></el-icon>
                </span>
            </p>

            <p class="details-box-item__footer-text default-content" v-if="footerLabel">{{footerLabel}}</p>
        </div>
    </div>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';

export default {
  components: {
    ArrowRightBold,
  },

  emits: ['on-navigate-link'],

  props: ['bgColor', 'isDarkBg', 'icon', 'data', 'label', 'ctaText', 'footerLabel', 'hasLinkTo'],

  methods: {
    onNavigateLink() {
      this.$emit('on-navigate-link');
    }
  }
};
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.link {
    color: #FFA500;
    text-decoration: none;
}

.details-box-item {
   background: v-bind('$props.bgColor');
   padding: 1rem;
   box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);
   border-radius: 20px;
   height: 140px;
   gap: .3rem;
   min-width: 50px;

   &__row {
       height: 100%;
       gap: 1rem;
   }

   &__data-text {
        font-size: 30px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0.25px;
        text-align: center;
   }
   &__label-text {
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0.25px;
        text-align: center;
        color: #0C0F4A;
   }
   &__data-text.light-text {
       color: #FFF;
   }
   &__label-text.light-text {
       color: #FFF;
   }
   &__cta-text {
       color: #FFA500;
        font-size: 12px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: right;
   }
   &__footer-text {
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        text-align: right;
        color: rgba(12, 15, 74, 0.5);
   }
}
.has-footer {
    gap: .3rem;
    height: 123px;
    .details-box-item__row {
        gap: .9rem;
    }
    .details-box-item__data-text {
        font-size: 18px;
   }
   .details-box-item__label-text {
        font-size: 12px;
        color: rgba(12, 15, 74, 0.5);
   }
   .details-box-item__label-text.light-text {
       color: rgba(255, 255, 255, 0.5);
   }
}

@include media-xs-max-width() {
    .details-box-item {
        &__data-text {
            font-size: 18px;
        }
        &__label-text {
            font-size: 10px;
        }
    }
    .has-footer {
        gap: .3rem;
        height: 123px;
        .details-box-item__label-text {
            font-size: 10px;
            color: rgba(12, 15, 74, 0.5);
        }
    }
}
</style>
