<template>
    <div class="dashboard-sole-trader-unverified w-100 h-100 grid">
      <section class="dashboard-sole-trader-unverified__container h-100 w-100">
        <PassportPreviewCard>
          <template #custom-footer>
            <section class="dashboard-sole-trader-unverified__footer w-100 h-auto flex-row ai-c">
                <div class="w-100 flex-row ai-c jc-fe">
                <ActionItemComponent
                    label="Explore Your Trade Passport"
                    icon="arrow-right.svg"
                    iconSize="20"
                    fontColor="#4B4BFF"
                    fontSize="14px"
                    :isReverse="true"
                    iconColor="#4B4BFF"/>
                </div>
            </section>
          </template>
        </PassportPreviewCard>
      </section>
      <section class="dashboard-sole-trader-unverified__container h-100 w-100">
        <DashboardInsightsCard/>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import PassportPreviewCard from '@/core/components/common/cards/PassportPreviewCard.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import DashboardInsightsCard from '@/modules/dashboard/components/cards/DashboardInsightsCard.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';

export default defineComponent({
  components: {
    DashboardInsightsCard,
    PassportPreviewCard,
    ActionItemComponent
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation']),
  },

  methods: {
  },
});
</script>
<style lang="scss" scoped>
.dashboard-sole-trader-unverified {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

  &__card {
    max-height: unset;
  }

  &__footer {
    min-height: 60px;
    border-top: 1px solid #C5D2F2;
  }
}
</style>
