<template>
    <InfoCardSlot
      class="company-workstation-detail"
      title="Your Company Workstations"
      iconName="services.svg"
      customPath="icons/trade-passport-icons"
      actionLabel="Manage Companies">
      <template #custom-content>
        <div class="company-workstation-detail__content flex-column h-100 w-100">
          <Typography variant="p" textWeight="500" textColor="rgba(12, 15, 74, 0.50)">
            Welcome, it’s great to see you - here’s the best things you can do on Iknowa right now!
          </Typography>
          <!-- NOTE: Create reusable Item for this  -->
          <div
            class="company-workstation-detail__item flex-column gap-0"
            v-for="(item, index) in data" :key="index">
            <Typography variant="h6">{{ item.label }}</Typography>
            <ActionItemComponent
              :label="item.actionLabel"
              fontColor="#FAA500"
              :isReverse="true"
              icon="arrow-right.svg"/>
          </div>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    ActionItemComponent
  },

  data() {
    return {
      data: [
        {
          label: 'Iknowa Ltd',
          actionLabel: 'Go To Company Workstation'
        },
        {
          label: 'HelloCrossman Ltd',
          actionLabel: 'Go To Company Workstation'
        },
        {
          label: 'Klein Builders Ltd',
          actionLabel: 'Go To Company Workstation'
        },
      ],
    };
  },
});
</script>
<style lang="scss" scoped>
.company-workstation-detail {
  max-height: 400px !important;
  min-height: 400px !important;
  height: auto;

  &__item {
    padding-bottom: 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid #C5D2F2;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
