export const HEADER_PROPERTIES = 'Properties';
export const HEADER_PROJECTS = 'Projects';
export const HEADER_PROFILE = 'Profile';
export const HEADER_JOBS = 'Jobs';
export const HEADER_DOCUMENTS = 'Documents';
export const HEADER_FINANCING = 'Financing';
export const HEADER_CHAT = 'Chat';
export const HEADER_ACTIVITY = 'Activity';
export const HEADER_EWALLET = 'E-wallet';
export const HEADER_DOCUMENTS_LIST = 'My Documents';
