<template>
    <InfoCardSlot
      class="account-insight"
      :title="title"
      iconName="services.svg"
      customPath="icons/trade-passport-icons"
      :actionLabel="actionLabel"
      :hideFooter="hideFooter"
      :hasCustomFooter="hasCustomFooter">
      <template #custom-content>
        <div class="account-insight__content flex-column h-100 w-100">
          <Typography variant="p" textWeight="500" textColor="rgba(12, 15, 74, 0.50)">
            Welcome, it’s great to see you - here’s the best things you can do on Iknowa right now!
          </Typography>
          <!-- NOTE: Create reusable Item for this  -->
          <div class="flex-column">
             <div class="flex-column gap-half">
                <Typography variant="h6">{{ secondaryTitle }}</Typography>
                <el-progress :percentage="20" :format="format"></el-progress>
            </div>
            <template v-for="(status, index) in data" :key="index">
              <SetupStatusItem :status="status" :index="index" @handle-click="handleClick"/>
            </template>
          </div>
        </div>
      </template>
      <template #custom-footer>
        <slot name="custom-footer"></slot>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Typography from '@/core/components/ui/Typography.vue';
import SetupStatusItem from '@/modules/workstation-setup/components/SetupStatusItem.vue';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
    SetupStatusItem,
  },

  emits: ['handle-click'],

  props: ['data', 'title', 'secondaryTitle', 'hideFooter', 'actionLabel'],

  data() {
    return {
    };
  },

  methods: {
    format(percentage) {
      return percentage === 100 ? 'Full' : `${percentage}%`;
    },

    handleClick(event) {
      this.$emit('handle-click', event);
    }
  }
});
</script>
<style lang="scss" scoped>
.account-insight {
  max-height: 590px !important;
  height: 100%;
}
</style>
