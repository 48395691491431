<template>
  <AccountInsightsCard
    class="dashboard-insight"
    :data="dashboardInsights"
    title="Getting the most out of iknowa"
    secondaryTitle="Your Iknowa account strength"
    :hideFooter="true"/>
</template>
<script>
import { defineComponent } from 'vue';

import AccountInsightsCard from '@/core/components/common/cards/AccountInsightsCard.vue';

export default defineComponent({
  components: {
    AccountInsightsCard
  },

  computed: {
  },

  data() {
    return {
      dashboardInsights: [
        {
          task: 'Explore Your Trade Passport',
          label: 'Everything under one roof',
          active: true
        },
        {
          task: 'Check out new opportunities',
          label: 'Earn a new accredited qualification'
        },
        {
          task: 'Create a workstation',
          label: 'xxx'
        },
        {
          task: 'Find new jobs',
          label: 'xxx'
        },
        {
          task: 'Take a tour of the dashboard',
          label: 'xxx'
        },
      ],
    };
  },

  methods: {
  }
});
</script>
<style lang="scss" scoped>
.dashboard-insight {
  max-height: 620px !important;
}
</style>
