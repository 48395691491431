<template>
    <div class="dashboard-limited-company-verified w-100 h-100 flex-column">
      <section class="dashboard-limited-company-verified__content grid">
        <div class="dashboard-limited-company-verified__container h-100 w-100 flex-column">
          <DashboardInsightsCard/>
        </div>
        <div class="dashboard-limited-company-verified__container h-100 w-100 flex-column">
          <JobsWidget/>
          <TeamManagementCard/>
        </div>
        <div class="dashboard-limited-company-verified__container h-100 w-100 flex-column">
          <EventWidget cardHeight="100%"/>
        </div>
      </section>
      <section class="dashboard-limited-company-verified__content grid grid-col-2">
        <CompanyActivityCard/>
        <RecommendedCoursesCard/>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import EventWidget from '@/core/components/common/widgets/EventWidget.vue';
import JobsWidget from '@/core/components/common/widgets/JobsWidget.vue';
import CompanyActivityCard from '@/modules/dashboard/components/cards/CompanyActivityCard.vue';
import DashboardInsightsCard from '@/modules/dashboard/components/cards/DashboardInsightsCard.vue';
import RecommendedCoursesCard from '@/modules/dashboard/components/cards/RecommendedCoursesCard.vue';
import TeamManagementCard from '@/modules/dashboard/components/cards/TeamManagementCard.vue';
import { TRADE_PASSPORT_ROUTE } from '@/modules/trade-passport/routes';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';

export default defineComponent({
  components: {
    DashboardInsightsCard,
    EventWidget,
    JobsWidget,
    RecommendedCoursesCard,
    CompanyActivityCard,
    TeamManagementCard
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters(USER_WORKSTATION, ['getTypeWorkstation']),
  },

  methods: {
    routeToTradesPassportPage() {
      this.$router.push({ name: TRADE_PASSPORT_ROUTE });
    }
  },
});
</script>
<style lang="scss" scoped>
.dashboard-limited-company-verified {

  &__content.grid {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  &__content.grid-col-2 {
    grid-template-columns: 1fr 2fr;
  }

  &__card {
    max-height: unset;
  }

  &__footer {
    min-height: 60px;
    border-top: 1px solid #C5D2F2;
  }
}
</style>
