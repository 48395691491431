// import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import events from '@/core/constants/event';
import { HEADER_JOBS } from '@/core/constants/header';
import JobsDetailsBoxComponent from '@/modules/dashboard/components/new-components/JobsDetailsBoxComponent.vue';
import { MANAGE_JOB_ROUTE } from '@/modules/manage-job/routes';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        JobsDetailsBoxComponent,
        InfoCardSlot
    },
    props: ['statusVerified'],
    data() {
        return {
            isLoading: false,
            myJobsStatuses: [],
            myQuotesStatuses: [],
            inProgressCount: '',
            notStartedCount: '',
            estimatesSentCount: '',
            loadingJobStatuses: true,
            loadingQuoteStatuses: true,
            myJobs: [],
            loadingMyJobs: true,
            HEADER_JOBS,
            events,
            /* eslint-disable global-require */
            jobsThumbnail: `${require('@/assets/images/projects-empty-image-png.png')}`,
        };
    },
    computed: {
        ...mapGetters(['isTradesperson']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        ...mapGetters(USERS_STORE, ['isWsEditor', 'isWsViewer']),
        widgetLabel() {
            const { isWsEditor, isWsViewer } = this;
            let label = 'Find new jobs';
            if (isWsEditor) {
                label = 'Manage jobs';
            }
            if (isWsViewer) {
                label = 'View Jobs';
            }
            return label;
        }
    },
    created() {
        // (this as any).initialize();
    },
    watch: {
        activeUserWorkstation: {
            immediate: true,
            handler(value) {
                if (value?.alias) {
                    this.initialize();
                }
            },
        },
    },
    methods: {
        ...mapActions(['setIsLoading']),
        ...mapActions(PROJECTS_STORE, ['getProjectPageList', 'getProjectJobStatusWithCountState', 'getProjectMyJobList', 'getProjectByRefId', 'getProjectQuotesList', 'getProjectJobList']),
        routeToFindJobs() {
            this.$router.push({ name: 'find-jobs' });
        },
        handleFunctionCall(event) {
            if (event === this.events.ROUTE_TO_FIND_JOBS) {
                this.routeToFindJobs();
            }
        },
        async initialize() {
            try {
                this.getMyJobStatuses();
                this.getMyQoutesStatuses();
                await this.getMyJobs();
                this.notStartedCount = this.myJobsStatuses[0]?.projectCount || '';
                this.inProgressCount = this.myJobsStatuses[1]?.projectCount || '';
                this.estimatesSentCount = this.myQuotesStatuses[2]?.projectCount || '';
            }
            catch (error) {
                this.$notify.error({
                    title: 'Error while initializing Jobs',
                    message: error
                });
            }
        },
        getMyJobStatuses() {
            this.getProjectJobStatusWithCountState('my-jobs')
                .then((response) => {
                this.myJobsStatuses = response;
                if (response.length > 0) {
                    this.notStartedCount = this.myJobsStatuses[0].projectCount;
                    this.inProgressCount = this.myJobsStatuses[1].projectCount;
                }
            })
                .catch(() => { });
        },
        getMyQoutesStatuses() {
            this.getProjectJobStatusWithCountState('my-quotes')
                .then((response) => {
                this.myQuotesStatuses = response;
                if (response.length > 0) {
                    this.estimatesSentCount = this.myQuotesStatuses[2].projectCount;
                }
            })
                .catch(() => { });
        },
        getMyJobs() {
            this.isLoading = true;
            const filters = {
                page: 1,
                limit: 2,
            };
            this.getProjectPageList(filters)
                .then((response) => {
                this.myJobs = response.data;
                const { limit, page, totalCount, totalPages } = response;
                this.projectListMetaData = {
                    limit,
                    page,
                    totalCount,
                    totalPages
                };
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        onManageJobs() {
            this.$router.push({ name: MANAGE_JOB_ROUTE });
        }
    },
});
