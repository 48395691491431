<template>
    <InfoCardSlot
      class="company-activity-card"
      title="Iknowa Recommended Courses"
      iconName="workspace-logo-two.svg"
      actionLabel="See All Activity">
      <template #custom-content>
        <div class="company-activity-card__content flex-column h-100 w-100">
          <Typography variant="p">
            No activity
          </Typography>
        </div>
      </template>
    </InfoCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import InfoCardSlot from '@/core/components/slots/InfoCardSlot.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    InfoCardSlot,
    Typography,
  },

  data() {
    return {
    };
  },
});
</script>
<style lang="scss" scoped>
.company-activity-card {
  max-height: 400px !important;
  min-height: 400px !important;
  height: auto;
}
</style>
