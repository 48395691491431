<template>
    <div class="setup-status-item flex-column gap-half">
        <div class="flex-row ai-fs">
          <div class="setup-status-item__counter flex-row ai-c jc-c">
            <Typography variant="custom" textSize="12px" textColor="#40AE42">{{ index + 1 }}</Typography>
          </div>
          <div class="flex-column gap-0">
            <Typography
              @click="handleClick(status?.key)"
              class="setup-status-item__task cursor-pointer"
              :class="{'active': status?.active}"
              variant="p">
                {{ status.task }}
            </Typography>
            <Typography
              class="setup-status-item__label"
              :class="{'active': status?.active}"
              variant="custom"
              textSize="12px"
              textWeight="500">
                {{ status.label }}
            </Typography>
          </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography
  },

  emits: ['handle-click'],

  props: ['status', 'index'],

  methods: {
    handleClick(key) {
      this.$emit('handle-click', key);
    }
  },
});
</script>
<style lang="scss" scoped>
 .setup-status-item {
    padding-bottom: .5rem;
    box-sizing: border-box;
    border-bottom: 1px solid #C5D2F2;

    &:last-child {
      border-bottom: none;
    }

    &__counter {
        background: rgba(64, 174, 66, .5);
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        border-radius: 100%;
    }

    &__task {
      text-decoration: underline;
    }

    &__task.active {
        color: #40AE42 !important;
    }
    &__label.active {
        color: rgba(12, 15, 74, 0.50) !important;
    }
  }
</style>
