<template>
    <div class="calendar-event-container flex-column" :class="{'no-gap': !isSmall}">
      <!-- FULL CALENDAR FOR LONG WIDGET -->
      <el-calendar v-model="selectedDate" v-if="!isSmall">
        <template #header>
          <CalendarHeader
            :currentYear="currentYear"
            :currentMonth="currentMonth"
            @on-set-next-month="nextMonth"
            @on-set-previous-month="previousMonth"
            @on-set-today="goToToday"/>
        </template>
      </el-calendar>
      <!-- ONE WEEK CALENDAR FOR SHORT WIDGET -->
      <section class="flex-column" v-else>
        <CalendarHeader
          :currentYear="currentYear"
          :currentMonth="currentMonth"
          :isDisplayOnly="true"/>
        <div class="calendar-event-container__row flex-row ai-c jc-sb">
          <div class="calendar-event-container__item flex-column ai-c jc-c" v-for="(date, index) in daysInCurrentWeek" :key="index">
            <p class="calendar-event-container__day default-text">{{date.abbrv}}</p>
            <p class="calendar-event-container__date default-text" :class="{'active': !index}">{{date.number}}</p>
          </div>
         </div>
      </section>

      <section class="calendar-event-container__content flex-column">
        <div class="calendar-event-container__wrapper flex-column" style="height: 100%;" v-if="filteredCalendarEvents.length">
          <!-- LAYOUT IF ITS SMALL WIDGET -->
          <div class="calendar-event-container__wrapper flex-column ai-fe" v-if="isSmall">
             <div class="calendar-event-container__wrapper flex-column">
              <EventItem
                v-for="(calendarEvent, index) in filteredCalendarEvents" :key="index"
                :schedule="calendarEvent"
                :noShadows="true"
                :time="calendarEvent.timeRange"
                :borderRadius="borderRadius"
                :iconSize="iconSize"/>
             </div>
             <ActionItemComponent
              label="View calendar"
              fontColor="#FFA500"
              icon="arrow-right-yellow.svg"
              :hasIconColor="false"
              :isReverse="true"
              fontSize="14px"
              @call-to-action="routeToCalendarPage"/>
          </div>
          <!-- LAYOUT IF ITS NOT SMALL WIDGET -->
          <el-scrollbar v-else>
            <div class="calendar-event-container__scrollbar-content flex-column" style="gap: 0;">
              <div class="calendar-event-container__time-item flex-row ai-fs" v-for="(time, index) in times.times(60)" :key="index">
                <div class="flex-row ai-c" style="width: 100%; position: relative;">
                  <p class="default-text">{{ time }}</p>
                  <el-divider border-style="double"/>
                  <template v-for="(calendarEvent, index) in filteredCalendarEvents" :key="index">
                    <EventTimeItem
                      v-if="isBetweenTime(calendarEvent, time)"
                      style="position: absolute; left: 3.7rem; top: 10px;"
                      :title="calendarEvent.title"
                      :time="calendarEvent.timeRange"
                      width="calc(100% - 5.5rem)"
                      :hasBorder="false"/>
                  </template>
                </div>
              </div>
            </div>
          </el-scrollbar>
          <div class="calendar-event-container__wrapper flex-row ai-fe jc-fe" v-if="!isSmall">
            <ActionItemComponent
              iconSize="50px"
              icon="add-icon-blue.svg"
               :hasIconColor="false"
              @call-to-action="onOpenEventFormModal"/>
          </div>
        </div>

        <EmptyState
          v-else
          :hasCustomContent="true" icon="empty-calendar-icon.svg"
          class="calendar-event-container__empty-state"
          style="left: calc(50% - 100px); top: calc(50% - 100px);">
          <template #>
            <Button
              style="box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.1); position: relative; width: auto;"
              :isActive="true"
              transparentFontColor="#4F55F0"
              :isTransparent="true"
              @handle-click="onOpenEventFormModal"
              buttonText="Create new event"/>
          </template>
        </EmptyState>
      </section>
    </div>

    <CreateEventFormModal
      :type="CALENDAR_EVENT_TYPE_PROPERTY"
      :viewSchedule="selectedSchedule"
      :show="showCreateEventFormModal"
      @on-cancel="onCloseEventFormModal"/>

</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import EventItem from '@/core/components/common/calendar/EventItem.vue';
import EventTimeItem from '@/core/components/common/calendar/EventTimeItem.vue';
import CreateEventFormModal from '@/core/components/common/modals/CreateEventFormModal.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Button from '@/core/components/ui/Button.vue';
import CalendarHeader from '@/core/components/ui/CalendarHeader.vue';
import EmptyState from '@/core/components/ui/EmptyState.vue';
import { CALENDAR_EVENT_TYPE_PROPERTY, SYSTEM_DATE_FORMAT } from '@/core/constants';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import times from '@/core/helpers/date-time.helper';
import GetDaysInCurrentWeekFilter from '@/filters/date.filter';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USER_CALENDAR_STORE } from '@/store/modules/user-calendar';

export default defineComponent({
  components: {
    CalendarHeader,
    Button,
    EmptyState,
    EventItem,
    ActionItemComponent,
    EventTimeItem,
    CreateEventFormModal,
  },
  props: {
    isSmall: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '50px'
    },
    iconSize: {
      type: String,
      default: '30px'
    },
  },

  watch: {
    selectedDate: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.setFilteredCalendarEvents(value);
        }
      }
    }
  },

  data() {
    return {
      selectedDate: new Date(), // set current date of calendar
      times,

      showCreateEventFormModal: false,

      selectedSchedule: null,

      calendarEvents: [],

      filteredCalendarEvents: [],

      CALENDAR_EVENT_TYPE_PROPERTY
    };
  },
  created() {
    this.loadSchedules();
  },
  computed: {
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),

    currentMonth() {
      return moment(this.selectedDate).format('MMMM');
    },
    currentYear() {
      return moment(this.selectedDate).format('yyyy');
    },
    daysInCurrentWeek() {
      return GetDaysInCurrentWeekFilter.getDaysInCurrentWeek();
    },
    firstTwoSchudules() {
      return this.schedules.slice(0, 2);
    },
  },
  methods: {
    ...mapActions(USER_CALENDAR_STORE, ['getSchedules', 'setViewEventDetailsModal']),

    adjustTopPosByTime() {
      // @TODO logic here should be
      // applied to move the event box's top position
    },

    adjustEventBoxHeightByTimeRange() {
      // @TODO logic here should be
      // applied to get the alter the height of the event box based on the given time range
    },

    isBetweenTime(calendarEvent, time) {
      const { startTime } = calendarEvent;
      const startTimeParts = startTime.split(':');
      const timeParts = time.split(':');
      const [startHour] = startTimeParts;
      const [hour] = timeParts;

      return startHour === hour;
    },

    onOpenEventFormModal(schedule = null) {
      if (schedule) {
        // set for view
        this.selectedSchedule = schedule;
      }
      this.showCreateEventFormModal = true;
    },

    onCloseEventFormModal(success = false) {
      this.showCreateEventFormModal = false;

      this.selectedSchedule = null;

      if (success) {
        this.calendarEvents = [];
        // we need to load and re-initialize to get the new list
        this.loadSchedules();
      }
    },

    async loadSchedules() {
      const { userId } = this.authenticatedUser;
      const options = {
        id: this.$route.params?.id,
        type: CALENDAR_EVENT_TYPE_PROPERTY
      };
      const calendarParams = {
        userId,
        options
      };

      await this.getSchedules(calendarParams).then((response) => {
        if (response?.data && response?.data.length) {
          response.data.forEach((row) => {
            const {
              id, schedule, title
            } = row;
            const [eventSchedule] = schedule;
            const { timeslots } = eventSchedule;
            const [record] = timeslots;

            this.calendarEvents.push({
              id,
              title,
              date: eventSchedule.date,
              timeRange: `${record.startTime} - ${record.endTime}`,
              startTime: record.startTime,
              endTime: record.endTime
            });
          });
        }

        // we need to filter per matching date
        this.setFilteredCalendarEvents(this.selectedDate);
      }).catch(() => {
        this.$notify.error({
          title: 'Error in retrieving schedules',
          message: GENERIC_ERROR_MESSAGE,
        });
      })
        .finally(() => {
        });
    },

    setFilteredCalendarEvents(selectedDate) {
      const { calendarEvents } = this;
      const formattedDate = moment(selectedDate).format(SYSTEM_DATE_FORMAT);

      this.filteredCalendarEvents = calendarEvents.filter((calendarEvent) => {
        return formattedDate === calendarEvent.date;
      });
    },

    previousMonth() {
      this.selectedDate = moment(this.selectedDate).subtract(1, 'month').toDate();
    },

    nextMonth() {
      this.selectedDate = moment(this.selectedDate).add(1, 'month').toDate();
    },

    goToToday() {
      this.selectedDate = new Date();
    },
  },
});
</script>
<style lang="scss" scooped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.el-divider {
  margin: 0;
}

.calendar-event-container {
  height: 100%;
  position: relative;

  &__container {
    width: 100%;
  }

  &__absolute-content {
    position: absolute;
  }
  &__events-container {
    padding-left: 4.4rem;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }

  &__content {
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  &__wrapper {
    width: 100%;
    gap: 0;
  }

  .el-calendar {
    .el-calendar__header {
      padding: 0 0 .5rem 0;
    }
    --el-calendar-cell-width: 20px;
    .el-calendar__body {
      padding: 12px 0 20px;
      .el-calendar-table td{
        border: none;
        border-radius: 100%;
        .el-calendar-day {
          height: 35px;
          max-height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
        }
      }
      .el-calendar-table td.is-today  {
        color: #4F55F0;
      }
      .el-calendar-table td.is-selected  {
        color: #FFF;
        // background: #4F55F0;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          // background: #4F55F0;
        }

        .el-calendar-day {
          border-radius: 100%;
          box-sizing: border-box;
          width: 35px;
          background: #4F55F0;
        }
      }

      .el-calendar-table {
        .el-calendar-table__row {
          .current {
            .el-calendar-day {
              &:hover {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }

  &__date-menu {
    width: auto;
    height: 36px;
    min-width: 110px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(42, 42, 42, 0.1);
    border-radius: 8px;
    padding: 0 .3rem;
    box-sizing: border-box;
  }
  &__row {
    gap: .5rem;
  }

  &__item {
    gap: .5rem;
  }

  &__day {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    color: rgba(12, 15, 74, 0.5);
  }
  &__date {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 100%;
  }
  &__date.active {
    color: #FFF;
    background: #4F55F0;
  }

  &__time-item {
    height: 80px;
  }
}

.no-gap {
  gap: 0;
}

@include media-sm-max-width() {
  .calendar-event-container__empty-state {
    left: calc(50% - 67px) !important;
    top: calc(50% - 80px) !important;
  }
}

@include media-xs-max-width() {
  .calendar-event-container {
    &__day {
        font-size: 10px;
    }
    &__date {
        font-size: 12px;
        width: 30px;
        height: 30px;
    }

    .el-calendar {
      .el-calendar__header {
        padding: 12px 0;
      }
      .el-calendar__body {
        .el-calendar-table td{
          .el-calendar-day {
            height: 42px;
          }
        }
        .el-calendar-table td.is-selected  {
          .el-calendar-day {
            width: 42px;
          }
        }
      }
    }
  }
  .calendar-event-container__empty-state {
    top: calc(50% - 80px);
  }
}
</style>
