// import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import DetailsBoxItemComponent from '@/modules/dashboard/components/new-components/DetailsBoxItemComponent.vue';
export default defineComponent({
    components: {
        // ArrowRightBold,
        DetailsBoxItemComponent,
        Typography,
        Icon
    },
    emits: ['handle-click'],
    props: ['inProgressCount', 'notStartedCount', 'estimatesSentCount'],
    data() {
        return {
            /* eslint-disable global-require */
            estimates: `${require('@/assets/icons/white-filled-estimates-icon.svg')}`,
            inProgress: `${require('@/assets/icons/white-filled-in-progress-icon.svg')}`,
            notStarted: `${require('@/assets/icons/yellow-filled-not-started-icon.svg')}`
            /* eslint-disable global-require */
        };
    },
    methods: {
        handleClick() {
            this.$emit('handle-click');
        }
    }
});
